<template>
  <el-card class="box-register">
    <template v-if="!success">
      <div class="header">
        <span>Daftar Baru</span>
      </div>
      <div class="body">
        <el-form ref="form" :rules="rules" :model="form">
          <el-form-item label="Email" prop="username">
            <el-input
              placeholder="Email Anda"
              v-model="form.username"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input
              placeholder="Password Anda"
              type="password"
              v-model="form.password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="width: 100%"
              :loading="loading"
              @click="handleRegister"
              type="primary"
              >Daftar</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="login-button">
        <span>Sudah punya akun?</span>
        <el-button @click="goLogin" ghost size="small"> Log In </el-button>
      </div>
    </template>
    <template v-else>
      <div class="anim">
        <lottie-animation
          ref="anim"
          :loop="true"
          :autoPlay="true"
          :animationData="require('@/assets/animation/lottiesuccess.json')"
        />
      </div>
      <div class="message">
        <span>
          Berhasil membuat akun baru, silahkan cek email anda untuk melakukan
          verifikasi!
        </span>
        <el-button @click="goHome">Beranda</el-button>
      </div>
    </template>
  </el-card>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      loading: false,
      success: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "Please input your email",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please input your password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
    goLogin() {
      this.$router.push({ name: "Login" });
    },
    handleRegister() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$notify({
            title: "Error",
            message: "Lengkapi Form Inputan!",
            type: "error",
          });
          return;
        }

        this.loading = true;

        this.$store
          .dispatch("setRegister", {
            username: this.form.username,
            password: this.form.password,
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Berhasil membuat akun baru!",
              type: "success",
            });
            this.loading = false;
            this.success = true;
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              message: err.response.data.errors ?  err.response.data.errors.username[0] : "Pembuatan akun gagal, silahkan coba kembali!",
              type: "error",
            });
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss">
.box-register {
  width: 380px;

  .el-card__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.message {
  padding: $space;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: $space;
}
.anim {
  padding: $space;
  > div {
    height: 200px;
  }
}

.body,
.header {
  padding: $space * 2;
}

.body {
  padding-top: 0;
  padding-bottom: 0;
}

.header {
  padding-bottom: 0;
}
.header {
  margin-bottom: $space * 2;
  span {
    font-weight: lighter;
    font-size: 2rem;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 30%;
      height: 4px;
      background-color: $green-color;
    }
  }
}

.login-button {
  background-color: darken(white, 4);
  padding: $space $space * 2;
  gap: $space / 2;
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    font-size: 0.875rem;
    color: darken(white, 60);
  }
  button{
    width: 100px;
  }
}
</style>

